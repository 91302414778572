import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

import seoImage from "../assets/logo/Stetrics-logo-og.webp";

const SEO = ({ location, description, title, image, jsonLd }) => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          defaultTitle: title
          titleTemplate
          defaultDescription: description
          twitter
          defaultIcon: icon
          siteUrl
        }
      }
    }
  `);

  const { defaultTitle, titleTemplate, defaultDescription, siteUrl, twitter, defaultIcon } = site.siteMetadata;

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    url: `${siteUrl}${location.pathname}`,
  };

  return (
    <Helmet title={seo.title} titleTemplate={titleTemplate}>
      <html lang='nl' />
      <meta name='description' content={seo.description} />

      {seo.siteUrl && <meta property='og:url' content={seo.url} />}

      <link rel='icon' type='image/svg+xml ' href={defaultIcon} />
      <link rel='alternate icon' href={defaultIcon} />

      {/* Meta tags */}
      <meta name='viewport' content='width=device-width, initial-scale=1.0' />
      <meta charSet='utf-8' />
      <link rel='canonical' href={seo.url} />

      {seo.title && <meta property='og:title' content={seo.title} />}

      {seo.description && <meta property='og:description' content={seo.description} />}

      {/* Open Graph */}
      {location && <meta property='og:url' content={seo.url} />}
      <meta property='og:image' content={image || seoImage || defaultIcon} />
      <meta property='og:title' content={title} key='ogtitle' />
      <meta property='og:site_name' content={site.siteMetadata.defaultTitle} key='ogsitename' />

      <script type='application/ld+json'>{JSON.stringify(jsonLd)}</script>
    </Helmet>
  );
};

export default SEO;
